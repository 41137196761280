<script lang="ts" setup>
defineProps<{
	transparent?: boolean;
	clearSectors?: boolean;
	showElements?: boolean;
}>();

const { imgWheelOfWins, isActiveSkinWheelOfWins } = useDepositStreakData({
	immediate: false
});

const prepareImgUrl = useImage();
const imgBorder = `url(${prepareImgUrl(`${imgWheelOfWins.value}/wheel/border-lights-1.png`, {
	format: "webp",
	width: 526,
	quality: "100"
})})`;
const imgBorder2 = `url(${prepareImgUrl(`${imgWheelOfWins.value}/wheel/border-lights-2.png`, {
	format: "webp",
	width: 526,
	quality: "100"
})})`;
const imgArrowLight = `url(${prepareImgUrl(`${imgWheelOfWins.value}/wheel/arrow-light.png`, {
	format: "webp",
	width: 153,
	quality: "100"
})})`;
const imgButtonLight = `url(${prepareImgUrl(`${imgWheelOfWins.value}/wheel/button-light.png`, {
	format: "webp",
	width: 160,
	quality: "100"
})})`;
const imgElements = `url(${prepareImgUrl(`${imgWheelOfWins.value}/elements.png`, {
	format: "webp",
	width: 776,
	quality: "100"
})})`;
</script>

<template>
	<div :class="['fake-wheel', { transparent }, { elements: showElements }, { skin: isActiveSkinWheelOfWins }]">
		<div class="inner-shell">
			<div class="percents">
				<AAnimationDrum antiClockWise>
					<NuxtImg
						:src="`${imgWheelOfWins}/wheel/sectors-percents${clearSectors ? '-clear' : ''}.png`"
						loading="lazy"
						quality="100"
						format="webp"
						width="923"
						height="auto"
						alt="sectors percent"
					/>
				</AAnimationDrum>
			</div>
			<div class="coins">
				<AAnimationDrum>
					<NuxtImg
						:src="`${imgWheelOfWins}/wheel/sectors-coins${clearSectors ? '-clear' : ''}.png`"
						loading="lazy"
						quality="100"
						format="webp"
						width="868"
						height="auto"
						alt="sectors coins"
					/>
				</AAnimationDrum>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.fake-wheel {
	position: relative;
	z-index: 1;

	.inner-shell {
		position: relative;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: v-bind(imgBorder) no-repeat center center / contain;
		animation: border-lights 1000ms linear infinite;
		&::after {
			content: "";
			z-index: 5;
			position: absolute;
			top: 38.8%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 43%;
			height: 45%;
			background: v-bind(imgButtonLight) no-repeat center center / contain;
		}
		& > div {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	img {
		width: 100%;
		height: 100%;
	}
	&::before {
		content: "";
		z-index: 3;
		position: absolute;
		top: -4.4%;
		left: 50%;
		transform: translateX(-50%);
		width: 50%;
		height: 57%;
		background: v-bind(imgArrowLight) no-repeat center center / contain;
		mask-image: linear-gradient(
			180deg,
			rgba(0, 0, 0, 1) 0,
			rgba(0, 0, 0, 1) 42%,
			rgba(0, 0, 0, 0) 45%,
			rgba(0, 0, 0, 0) 100%
		);
	}
	.percents {
		z-index: 2;
		width: 116%;
		height: 116%;
	}
	.coins {
		z-index: 4;
		width: 108%;
		height: 108%;
	}

	&.transparent .inner-shell {
		mask-image: linear-gradient(
			180deg,
			rgba(0, 0, 0, 1) 0,
			rgba(0, 0, 0, 1) 40%,
			rgba(0, 0, 0, 0) 66%,
			rgba(0, 0, 0, 0) 100%
		);
	}
	&.elements::after {
		content: "";
		position: absolute;
		top: -10%;
		left: -24%;
		width: 150%;
		height: 100%;
		z-index: 3;
		background: v-bind(imgElements) no-repeat center center / contain;
		animation: elems 7s linear infinite;
	}
	&.skin::after {
		top: -26%;
		left: -15%;
		width: 150%;
	}
}
@keyframes border-lights {
	50% {
		background: v-bind(imgBorder2) no-repeat center center / contain;
	}
}
@keyframes elems {
	50% {
		transform: scale(1.05);
	}
}
</style>
